














































  import { PRODUCTS } from '@/constant/Enums';
  import { ServerResponse } from '@/services/response.types';
  import { CityModel, ProvinceModel } from '@/store/location/types';
  import { ProductPropertyStateModel, FormComparePropertyInterface, FormComparePropertyModel } from '@/store/product/properti/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { Action, Mutation, State } from 'vuex-class';

  @Component({
    name: 'compareProperty',
    components: {},
    mixins: [],
  })
  export default class compareProperty extends Vue {
    @Action('GetProvince', { namespace: 'location' }) GetProvince!: (params?: { state_id: any }) => Promise<ServerResponse>;
    @Action('GetCity', { namespace: 'location' }) GetCity!: (params?: { state_id: any }) => Promise<ServerResponse>;
    @Action('GetProperty', { namespace: 'product/properti' }) GetProperty!: () => Promise<ServerResponse>;
    @Action('CompareAction', { namespace: 'product/properti' }) CompareAction!: (form?: FormComparePropertyInterface) => Promise<ServerResponse>;

    @State('province', { namespace: 'location' }) province!: ProvinceModel[];
    @State('city', { namespace: 'location' }) city!: CityModel[];
    @State((state) => state, { namespace: 'product/properti' }) property!: ProductPropertyStateModel;
    @State('form', { namespace: 'product/properti' }) formProperty!: FormComparePropertyModel;

    @Mutation('set-form', { namespace: 'product/properti' }) SetForm!: (form: FormComparePropertyInterface) => void;

    @Prop({ default: null }) title!: string;
    @Prop({ default: null }) desciption!: string;
    @Prop({ default: 'BANDINGKAN SEKARANG' }) buttonTitle!: string;

    public get form(): FormComparePropertyInterface {
      return this.formProperty;
    }

    public set form(v: FormComparePropertyInterface) {
      this.SetForm(v);
    }

    public routerName = 'compare-' + PRODUCTS[2].name.toLowerCase().replace(' ', '');

    public get optionProvince(): { text: string; value: string }[] {
      return this.province?.map((item) => {
        return {
          text: item.state_name,
          value: item.state_id,
        };
      });
    }

    public get optionCity(): { text: string; value: string }[] {
      return this.city?.map((item) => {
        return {
          text: item.city_name,
          value: item.city_id,
        };
      });
    }

    public created(): void {
      this.GetProvince();
    }

    public mounted(): void {
      this.GetProperty().then(() => {
        let history = localStorage.getItem('property-history');
        if (typeof history === 'string') {
          this.form = JSON.parse(history);
          if (this.form.state_id) this.GetCity({ state_id: this.form.state_id });
          if (this.$route.name === this.routerName) this.CompareAction();
        }
      });
    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      localStorage.setItem('property-history', JSON.stringify(this.form));
      this.CompareAction().then(() => {
        this.$route.name !== this.routerName ? this.$router.push({ name: this.routerName }) : 1;
      });

      this.$router.push({ name: this.routerName });
    }
  }
